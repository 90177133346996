import React from 'react';

import { Layout } from '../../../../components/layouts';
import { SEO } from '../../../../components/common';
import {
  EgyptianCottonBeddingSetIntroSection,
  EgyptianCottonBeddingSetFeatureSection,
  EgyptianCottonBeddingSetUpsellHeroSection,
  EgyptianCottonBeddingSetAccordionSection,
  EgyptianCottonBeddingSetsUpsellGridSection,
  WoosaPromiseIconGridSection,
  MattressLateralSection,
  EgyptianCottonPillowcaseSetReviewHeroSection,
  EgyptianCottonPillowcaseSetProductSection,
} from '../../../../components/sections';
import { CountryCode } from '../../../../utils/constants';

const EgyptianCottonPillowcaseSetPage: React.FC = () => (
  <Layout countryCode={CountryCode.SG}>
    <SEO
      title="Shop 100% Egyptian Cotton Pillowcase Set"
      description="Shop pillowcases from our range of 100% Pure Egyptian Giza Cotton Bedsheets. Luxuriously soft and crafted from long-staple cotton for exceptional durability, experience unmatched comfort and softness that gets even better with every wash."
      meta={[]}
    />
    <EgyptianCottonPillowcaseSetProductSection />
    <EgyptianCottonBeddingSetIntroSection />
    <EgyptianCottonBeddingSetFeatureSection />
    <EgyptianCottonBeddingSetUpsellHeroSection />
    <EgyptianCottonBeddingSetAccordionSection />
    <EgyptianCottonPillowcaseSetReviewHeroSection />
    <EgyptianCottonBeddingSetsUpsellGridSection />
    <WoosaPromiseIconGridSection version="v2" />
    <MattressLateralSection version="v2" />
  </Layout>
);

export default EgyptianCottonPillowcaseSetPage;
